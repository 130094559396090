@import url(./vendor/fonts/fonts.css);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "TTRoundsNeue", Arial, Helvetica, sans-serif;
  color: #484242;
  background-color: #f5f5f5;
}

li {
  list-style: none;
}